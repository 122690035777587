import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { Grid, Row, Col } from 'react-flexbox-grid'
import Layout from '../../components/Layout'
import WorkTitleBlock from '../../components/WorkTitleBlock'
import FullPage from '../../components/FullPage'
import findImage from '../../utils/findImage'
//import { Transition } from 'react-transition-group'

export const frontmatter = {
  title: 'Crazy Ropes Poster',
  client: 'IDE Group',
  type: 'Print',
  path: '/works/print/crazy-ropes/',
  date: '2008-10-01',
  image: 'crazy-ropes-demo',
  favorite: true,
}

/*
const duration = 300
const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered:  { opacity: 1 },
}
*/

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { regex: "^/crazy-ropes/i/" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <FullPage align="top">
        <Grid fluid>
          <Row>
            <Col xs={12} sm={4}>
              <h1>{frontmatter.title}</h1>
              <p>
                The goal was to&nbsp;create an&nbsp;award that will be rewarding
                to&nbsp;get and fun to&nbsp;look&nbsp;at.
              </p>
            </Col>
            <Col xs={12} sm={5}>
              <figure className="text-center">
                <Image
                  fluid={findImage(images, 'crazy-ropes')}
                  alt="Crazy Ropes diploma"
                />
              </figure>
            </Col>
          </Row>
        </Grid>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
